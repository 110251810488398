import React, { useEffect, useState } from "react";
import { RxCross2 } from "../../../assets/icons";
import Button from "../../common/Button";
// import {uploadFile} from "../../../services/files";
import toast from "react-hot-toast";
import Input from "../../common/Input";
import { createFolder } from "../../../services/folders/createFolder";
import { useParams } from "react-router-dom";
import { getAllCourses } from "../../../services/course";

interface UploadFileProps {
  isClose?: any;
}

const CreateFolder: React.FC<UploadFileProps> = ({ isClose }) => {
  const params = useParams();
  const param = params["*"] || null;
  const [courses, setCourses] = useState([]);
  const [value, setValue] = useState<{
    folderName: string;
    parentFolder: string | null;
    courseId: string;
  }>({
    folderName: "",
    parentFolder: param,
    courseId: "",
  });

  //Get All Courses
  useEffect(() => {
    (async () => {
      const data = await getAllCourses();
      if (data?.statusCode == 200) {
        console.log("data", data);
        setCourses(data?.data);
      }
    })();
  }, []);

  const submitHandlder = async () => {
    try {
      const response = await createFolder(value);
      if (response?.status) {
        toast.success("Folder create Successfully!");
      }
    } catch (error) {
      throw error;
    } finally {
      setValue({
        folderName: "",
        parentFolder: null,
        courseId: "",
      });
      isClose((prev: any) => ({ ...prev, createFolder: false }));
    }
  };
  const closeHandle = (e: any) => {
    if (e.target.id == "uploadBox")
      isClose((prev: any) => ({ ...prev, createFolder: false }));
  };

  return (
    <main
      id="uploadBox"
      onClick={closeHandle}
      className="absolute top-0 flex justify-center items-center z-10 inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white w-[35%] h-[44vh] rounded space-y-2 p-3 text-black  shadow-lg">
        <div className="flex flex-row-reverse">
          <button
            onClick={(e) =>
              isClose((prev: any) => ({ ...prev, createFolder: false }))
            }
            className=" cursor-pointer hover:text-red-500 font-semibold"
          >
            <RxCross2 size={24} />
          </button>
        </div>
        <div>
          <h1 className="text-black  text-xl font-semibold ">Create Folder</h1>
          <Input
            className=""
            type="text"
            placeHolder="Folder Name"
            labelText="Folder Name"
            value={value?.folderName}
            onChange={(e) => setValue({ ...value, folderName: e.target.value })}
            required
          />
        </div>
        {param == null && (
          <div>
            <label htmlFor="" className="p-1">
              Folder attact with<span className="text-customRed">*</span>
            </label>
            <select
              value={value?.courseId}
              onChange={(e) => setValue({ ...value, courseId: e.target.value })}
              name=""
              id=""
              className="py-3 px-1  border-[1px] w-full rounded  text-gray-700 outline-customBlue"
            >
              <option value="" selected disabled>Select One</option>
              {courses?.map((course: any) => {
                return (
                  <option value={course?._id}>{course?.courseName}</option>
                );
              })}
            </select>
          </div>
        )}

        <Button onClick={submitHandlder}>Create Folder</Button>
      </div>
    </main>
  );
};

export default CreateFolder;
