import React from "react";
import Logo from "../../assets/logo/logo.png";

interface IndexProps {
  children?: any;
}

const AuthLayout: React.FC<IndexProps> = ({ children }) => {
  return (
    <main className="grid grid-cols-2 h-[100vh] w-full overflow-y-hidden ">
      <div className="bg-white flex flex-col items-center justify-around py-5 shadow-2xl shadow-customRed">
        {/* <h2 className="text-black font-[600] text-[34px]">Secu  re FTP Server</h2> */}
        <img src={Logo} alt="logo" />
       
        <h2>Empowered by <a href="https://effectualservices.com" target="_blank" rel="noreferrer" className="text-customRed font-semibold">Effectual Knowledge Services.</a></h2>
      </div>
      <section className="w-full h-[100vh]">{children}</section>
    </main>
  );
};

export default AuthLayout;
