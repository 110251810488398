import axiosObject from "../config";

export const createFolder = async (payload: any) => {
  try {
    const uri: string = "folder/create";
    const response = await axiosObject.post(uri, payload);

    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
