import React, { useEffect, useState } from "react";
import Table from "./Table";
import { getAllUsers } from "../../../services/user";

interface IndexProps {
  // define props here
}

const Students: React.FC<IndexProps> = (props) => {
  const [users, setUser] = useState<any>([]);
  useEffect(() => {
    (async () => {
      const response = await getAllUsers();
      if (response?.statusCode == 200) {
        setUser([...response?.data?.user]);
      }
    })();
  }, []);

  return (
    <main>
      <Table users={users} />
    </main>
  );
};

export default Students;
