import React from "react";

interface IndexProps {
  // define props here
}

const Loader: React.FC<IndexProps> = (props) => {
  return (
    <main>
      <div
        className="inline-block h-[22px] w-[22px] animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
        role="status"
      >
       
      </div>
    </main>
  );
};

export default Loader;
