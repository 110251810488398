import axiosObject from "../config";

export const paymentSuccess = async (payload: any, userId: string) => {
  console.log("recieve in payload", payload);
  try {
    const uri: string = `course/verify-payment/${userId}`;
    const response = await axiosObject.post(uri, payload);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
