import axiosObject from "../config";

export const uploadFile = async (parentFolder: string | null, payload: any) => {
  try {
    const uri: string = `file/upload/${parentFolder}`;
    const response = await axiosObject.post(uri, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
