import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../layout/DashboardLayout";
import Folder from "../components/Dashboard/Folder";
import Students from "../components/Dashboard/Students";
import Authanticated from "./AuthanticatedRoutes";
import NotFound from "../components/NotFound";
import Cart from "../components/Dashboard/Cart";

interface AuthRoutesProps {
  // define props here
}

const DashboardRoute: React.FC<AuthRoutesProps> = (props) => {
  return (
    <Authanticated>
      <DashboardLayout>
        <Routes>
          <Route path="/" element={<Folder />} />
          <Route path="/:folderId" element={<Folder />} />
          <Route path="/students" element={<Students />} />
          <Route path="/cart/:courseId" element={<Cart />} />
          {/* <Route path="/*" element={<NotFound />} /> */}
        </Routes>
      </DashboardLayout>
    </Authanticated>
  );
};

export default DashboardRoute;
