import React from "react";
import Header from "../../components/Dashboard/Header";
import Sidebar from "../../components/Dashboard/Sidebar";

interface IndexProps {
  children?: any;
}

const DashboardLayout: React.FC<IndexProps> = ({ children }) => {
  return (
    <main className="w-full h-[100vh] fixed">
      <Header />
      <section className="flex w-full h-[calc(100vh-73px)]">
        {" "}
        {/* Adjust height based on header height */}
        <aside className="w-[15%] h-full  top-[65px]">
          {" "}
          {/* Sidebar is fixed under the header */}
          <Sidebar />
        </aside>
        <section className="w-[85%]  p-4 overflow-y-auto h-full">
          {" "}
          {/* Content section takes remaining width and becomes scrollable */}
          {children}
        </section>
      </section>
    </main>
  );
};

export default DashboardLayout;
