import axiosObject from "../config";

export const getAllCourses = async () => {
  try {
    const uri: string = `course/get-all`;
    const response = await axiosObject.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
