import axiosObject from "../config";

export const resetPassword = async (payload: any) => {
  try {
    const uri: string = `auth/reset-password?email=${payload}`;
    const response = await axiosObject.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
