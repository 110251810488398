import axiosObject from "../config";

export const createOrder = async (payload: any, userId: string) => {
  try {
    const uri: string = `course/create-order/${userId}?amount=${payload}`;
    const response = await axiosObject.get(uri);

    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
