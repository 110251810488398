import axiosObject from "../config";

export const getAllUsers = async () => {
  try {
    const uri: string = "user/get-all";
    const response = await axiosObject.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
