import axiosObject from "../config";

export const setPasswordService = async (
  token: string | undefined,
  payload: any
) => {
  try {
    const uri: string = `auth/set-password?token=${token}`;
    const response = await axiosObject.post(uri, payload);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
