import { MdDashboardCustomize } from "../assets/icons";

export interface SIDEBAR{
  name?:string,
  state:string,
  link:string,
}
export const adminSideBar:SIDEBAR[] = [
  {
    name: "Dashboard",
    state: "dashboard",
    link: "/dashboard",
    // avatar:<MdDashboardCustomize size={23} />
  },
  {
    name: "Create Folder",
    state: "createFolder",
    link: "",
  },
  {
    name: "Upload File",
    state: "uploadFile",
    link: "",
  },
  {
    name: "Students",
    state: "students",
    link: "/dashboard/students",
  },
];

export const userSideBar:SIDEBAR[] = [
  {
    name: "Dashboard",
    state: "dashboard",
    link: "/dashboard",
    // avatar:<MdDashboardCustomize size={23} />
  },
];
