import NotFoundSVG from "../../assets/images/notFoundSVG.svg";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <main className="flex flex-col justify-center items-center pb-10">
      <img src={NotFoundSVG} alt="Not-found" />
      <div className="flex flex-col justify-center items-center gap-3">
        <h1 className="text-xl font-bold">404 - PAGE NOT FOUND</h1>
        <p className="w-[600px] text-center text-sm font-normal">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <Link
          to={"/"}
          className="bg-customBlue py-2 px-5 rounded-full text-sm text-white font-semibold"
        >
          GO TO HOMEPAGE
        </Link>
      </div>
    </main>
  );
}
