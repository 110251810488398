import React from "react";

interface ButtonProps {
  children?: React.ReactNode;
  type?: "submit" | "reset";
  text?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({children, ...props}) => {
  const {type, onClick, disabled} = props;
  return (
    <main>
      <button
        onClick={onClick}
        disabled={disabled}
        className={`text-white bg-customBlue border-[2px]  hover:border-[2px] rounded-md hover:bg-white  hover:border-customBlue hover:text-customBlue  w-full py-2 text-center font-semibold  disabled:cursor-not-allowed disabled:bg-red-500`}
      >
        {children}
      </button>
    </main>
  );
};

export default Button;
