import React, {ChangeEvent} from "react";

interface InputProps {
  type?: "text" | "password" | "email" | "number" | "checkbox";
  id?: string;
  placeHolder?: string;
  className?: string;
  required?: any;
  labelText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  name?: string;
  error?: boolean;
  onFocus?: () => void;
}

const Input: React.FC<InputProps> = ({
  type,
  required,
  labelText,
  onChange,
  value,
  name,
  error,
  id,
  placeHolder,
  className,
  onFocus,
}) => {
  const [touched, setTouched] = React.useState(false);

  const handleFocus = () => {
    setTouched(true);
    if (onFocus) onFocus();
  };

  return (
    <main className="text-gray-700 flex flex-col gap-[2px]  font-[400]  text-[16px]">
      <label htmlFor={labelText} className="px-1">
        {labelText}
        <span className="text-red-600 font-bold">{required && "*"}</span>
      </label>
      <input
        type={type}
        id={id}
        value={value}
        name={name}
        onFocus={handleFocus}
        onChange={onChange}
        placeholder={placeHolder}
        required={required}
        className={`text-gray-800 font-[400] p-2 border-[1px] rounded-md w-full outline-customBlue ${className}`}
      />
      {touched && required && value == "" && (
        <p className="text-red-500 text-[13px] px-1">Input filed can&apos;t be empty!</p>
      )}
    </main>
  );
};

export default Input;
