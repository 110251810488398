import axiosObject from "../config";

export const userData = async (userId: string) => {
  try {
    const uri: string = `user/data/${userId}`;
    const response = await axiosObject.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
