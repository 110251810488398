import {CustomDragDrop} from "../CustomDragDrop";
import {useState} from "react";

export default function DragComponent({files, setFiles}: any) {
  function uploadFiles(f: any) {
    setFiles([...files, ...f]);
  }

  function deleteFile(indexImg: any) {
    const updatedList = files.filter((ele: any, index: number) => index !== indexImg);
    setFiles(updatedList);
  }

  return (
    <div className="bg-white shadow rounded-lg w-full px-5 pt-3 pb-5 m-2">
      <div className="pb-[8px] border-b border-[#e0e0e0]">
        <h2 className="text-black text-[17px] font-[600]">Drag and Drop Files</h2>
      </div>
      <CustomDragDrop
        ownerLicense={files}
        onUpload={uploadFiles}
        onDelete={deleteFile}
        count={5}
        formats={["xls", "xlsx", "csv", "sheet", "spreadsheetml.sheet", "png"]}
      />
    </div>
  );
}
