import React from "react";
import { Navigate } from "react-router-dom";

interface AuthanticationProps {
  // define props here
  children?: any;
}

const Authanticated: React.FC<AuthanticationProps> = ({ children }) => {
  let token = localStorage.getItem("auth_token");
  if (token) {
    token = JSON.parse(token);
  }
  return token ? children : <Navigate to={"/"} />;
};

export default Authanticated;
