import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "../components/auth/SignIn/SignIn";
import ResetPassword from "../components/auth/ResetPassword";
import AuthLayout from "../layout/AuthLayout";
import SetPassword from "../components/auth/SetPassword";
import NotFound from "../components/NotFound";
import UnAuthanticated from "./UnauthanticatedRoutes";

interface AuthRoutesProps {
  // define props here
}

const AuthRoutes: React.FC<AuthRoutesProps> = (props) => {
  return (
    <UnAuthanticated>
      <AuthLayout>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/set-password/:resetToken" element={<SetPassword />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </AuthLayout>
    </UnAuthanticated>
  );
};

export default AuthRoutes;
