import { setPasswordService } from "../../../services/auth/setPassword";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";

const SetPassword = () => {
  const navigate = useNavigate();
  const { resetToken } = useParams<{ resetToken: string }>();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(true);
  const [error, setError] = useState<string | null>();

  const [values, setValues] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (values?.password !== values?.confirmPassword) {
      setError("Password must be same");
    } else {
      setError(null);
    }
  }, [values]);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (values.password !== values.confirmPassword) {
        toast.error("Password and Confirm password not same!");
        return;
      }
      const payload = {
        password: values?.password,
      };
      const response = await setPasswordService(resetToken, payload);
      if (response.statusCode == 200) {
        toast.success(response?.message);
        navigate("/");
      } else {
        toast.error(response?.response?.data?.message);
      }
    } catch (error) {
      throw new Error("erron on login!");
    }
  };

  return (
    <div className="w-full h-full flex flex-col justify-center  sm:mx-auto bg-customBlue shadow-2xl shadow-customRed px-28">
      <div className="flex flex-col gap-1">
        <h2 className="font-bold text-[24px] text-start text-white">
          Set Password
        </h2>
        <h4 className=" text-[16px] text-white">Please set your password!</h4>
      </div>
      <div className="w-full text-white ">
        <form
          className="flex flex-col py-4 "
          onSubmit={submitHandler}
          method="POST"
        >
          <label htmlFor="" className="text-white font-[600] text-[15px]">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            required
            value={values.password}
            onChange={handleChange}
            className="border-[1px]  my-2 px-3 py-3 text-gray-800 font-semibold rounded-lg outline-[#3B82F6] mb-3"
            placeholder="New Password"
          />
          <label htmlFor="" className="text-white font-[600] text-[15px] ">
            Confirm Password{" "}
          </label>

          <div className="  relative">
            <input
              type={passwordVisible ? "password" : "text"}
              name="confirmPassword"
              id="confirmPassword"
              required
              value={values.confirmPassword}
              onChange={handleChange}
              className=" border-[1px]  w-full  px-3 py-3 text-gray-800 font-semibold  outline-[#3B82F6]  my-2 rounded-lg "
              placeholder="Confirm Password"
            />
            <span
              className="px-1 text-gray-600 absolute inset-y-0 right-2 pl-2 top-5 cursor-pointer "
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <IoEye size={23} /> : <IoEyeOff size={23} />}
            </span>
          </div>
          {error && <span className="text-red-500 px-6">{error}</span>}

          <button
            type="submit"
            className="px-20 py-2 bg-customRed text-[18px]  rounded text-white mt-5 w-full"
          >
            Set Password
          </button>
          <div className="flex items-center  flex-row-reverse text-white mt-1 justify-center">
            <Link
              to={"/"}
              className="cursor-pointer px-2 text-[14px] hover:underline"
            >
              Back to Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
