import React, { useState } from "react";
import DragComponent from "./DragComponent";
import { RxCross2 } from "../../../assets/icons";
import Button from "../../common/Button";
// import {uploadFile} from "../../../services/files";
import toast from "react-hot-toast";
import { uploadFile } from "../../../services/file";
import { useParams } from "react-router-dom";

interface UploadFileProps {
  isClose?: any;
}

const UploadFile: React.FC<UploadFileProps> = ({ isClose }) => {
  const params = useParams();
  const param = params["*"] || null;
  const [files, setFiles] = useState<any>([]);
  console.log(files);

  const uploadFileOnServer = async () => {
    try {
  
      const response = await uploadFile(param,files);
      if (response?.statusCode == 201) {
        toast.success(response?.message);
      }
    } catch (error) {
      throw error;
    } finally {
      isClose((prev: any) => ({ ...prev, uploadFile: false }));
    }
  };
  const closeHandle = (e: any) => {
    if (e.target.id == "uploadBox")
      isClose((prev: any) => ({ ...prev, uploadFile: false }));
  };

  return (
    <main
      id="uploadBox"
      onClick={closeHandle}
      className="absolute top-0 flex justify-center items-center z-10 inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white w-[40%] h-[60vh] rounded p-3 text-black  shadow-lg">
        <div className="flex flex-row-reverse">
          <button
            onClick={(e) =>
              isClose((prev: any) => ({ ...prev, uploadFile: false }))
            }
            className=" cursor-pointer hover:text-red-500 font-semibold"
          >
            <RxCross2 size={24} />
          </button>
        </div>
        <h1 className="text-black  text-xl font-semibold">Upload File here</h1>
        <DragComponent files={files} setFiles={setFiles} />
        <Button onClick={uploadFileOnServer}>Upload Files</Button>
      </div>
    </main>
  );
};

export default UploadFile;
