import React, { useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Configure the PDF worker source
interface UploadFileProps {
  isClose?: (callback: any) => void;
  filePath?: string;
}

const ShowPDF: React.FC<UploadFileProps> = ({ isClose, filePath }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const closeHandle = (e: any) => {
    if (e.target.id === "uploadBox") {
      isClose && isClose((prev: any) => ({ ...prev, PDF: false }));
    }
  };

  return (
    <main
      id="uploadBox"
      onClick={() =>
        isClose && isClose((prev: any) => ({ ...prev, PDF: false }))
      }
      className="absolute top-0 flex justify-center items-center z-10 inset-0 bg-black bg-opacity-50"
    >
      <span
        onClick={closeHandle}
        className="absolute top-10 right-20 text-customRed h-8 w-8 rounded-full border-2 flex items-center justify-center cursor-pointer border-customRed text-xl"
      >
        X
      </span>
      <div className="bg-white w-[98vw] h-[98vh] rounded space-y-2 p-3 text-black  shadow-lg">
        <embed
          src={`${filePath}#toolbar=0`}
          type=""
          className="w-full h-full"
        />
      </div>
    </main>
  );
};

export default ShowPDF;
