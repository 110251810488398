import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthRoutes from "./routes/AuthRoutes";
import DashboardRoute from "./routes/DashboardRoute";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <BrowserRouter>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path="/*" element={<AuthRoutes />} />
        <Route path="/dashboard/*" element={<DashboardRoute />} />
        {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
