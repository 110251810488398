import React from "react";
import { Navigate } from "react-router-dom";

interface AuthanticationProps {
  // define props here
  children?: any;
}

const UnAuthanticated: React.FC<AuthanticationProps> = ({ children }) => {
  let token = localStorage.getItem("auth_token");
  if (token) {
    token = JSON.parse(token)?.access_token;
  }
  return !token ? children : <Navigate to={"/dashboard"} />;
};

export default UnAuthanticated;
