import { resetPassword } from "../../../services/auth/resetPassword";
import { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState<string>();
  const navigate = useNavigate();

  
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await resetPassword(email);
      if (response.statusCode == 200) {
        toast.success(response?.message);
        navigate("/");
      } else {
        toast.error(response?.response?.data?.message);
      }
    } catch (error) {
      throw new Error("erron on Reset Password!");
    } finally {
      setEmail("");
    }
  };
  return (
    <div className="w-full h-full flex flex-col justify-center  sm:mx-auto bg-customBlue shadow-2xl shadow-customRed px-28">
      <div className="flex flex-col gap-2">
        <h2 className="font-bold text-[24px] text-start text-white">
          Forgot Password
        </h2>
        <h4 className=" text-[14px] text-white">
          Please enter your email address to receive a verification code
        </h4>
      </div>
      <div className="w-full text-white ">
        <form
          className="flex flex-col py-4 "
          onSubmit={submitHandler}
          method="GET"
        >
          <label htmlFor="" className="text-white font-[600] text-[15px]">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border-[1px]  my-2 px-3 py-3 text-gray-800 font-semibold rounded-lg outline-[#3B82F6] mb-3"
            placeholder="Email address"
          />
          <button
            type="submit"
            className="px-20 py-2 bg-customRed text-[18px]  rounded text-white mt-5 w-full"
          >
            Forgot Password
          </button>
          <div className="flex items-center justify-center  flex-row-reverse text-white mt-1">
            <Link
              to={"/"}
              className="cursor-pointer px-2 text-[14px] hover:underline"
            >
              Go to Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
