import React, { useEffect, useState } from "react";
import { RxCross2 } from "../../../assets/icons";
import Button from "../../common/Button";
// import {uploadFile} from "../../../services/files";
import toast from "react-hot-toast";
import Input from "../../common/Input";
import { createFolder } from "../../../services/folders/createFolder";
import { useParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { getAllFolder } from "../../../services/folders/getAllFolder";

interface UploadFileProps {
  isClose?: any;
}

const FolderAccess: React.FC<UploadFileProps> = ({ isClose }) => {
  const params = useParams();
  const [folders, setFolders] = useState<any>([]);
  const [selectedCities, setSelectedCities] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await getAllFolder("0");
      if (response?.statusCode == 200) {
        // console.log('response',response)
        setFolders(
          [...response?.data?.folders].map((item) => {
            return { name: item?.folderName, code: item?._id };
          })
        );
      }
    })();
  }, []);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  const param = params["*"] || null;
  const [value, setValue] = useState<{
    folderName: string;
    parentFolder: string | null;
  }>({
    folderName: "",
    parentFolder: param,
  });

  const submitHandlder = async () => {
    try {
      const response = await createFolder(value);
      if (response?.status) {
        toast.success("Folder create Successfully!");
      }
    } catch (error) {
      throw error;
    } finally {
      setValue({
        folderName: "",
        parentFolder: null,
      });
      isClose(false);
    }
  };
  const closeHandle = (e: any) => {
    if (e.target.id == "uploadBox") isClose(false);
  };

  return (
    <main
      id="uploadBox"
      onClick={closeHandle}
      className="absolute top-0 flex justify-center items-center z-10 inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white w-[35%] h-[34vh] rounded space-y-2 p-3 text-black  shadow-lg">
        <div className="flex flex-row-reverse">
          <button
            onClick={(e) => isClose(false)}
            className=" cursor-pointer hover:text-red-500 font-semibold"
          >
            <RxCross2 size={24} />
          </button>
        </div>
        <h1 className="text-black  text-xl font-semibold ">Create Folder</h1>
        <div className="card flex justify-content-center  ">
          <MultiSelect
            value={selectedCities}
            onChange={(e) => setSelectedCities(e.value)}
            options={folders || []}
            optionLabel="name"
            placeholder="Select Folders"
            maxSelectedLabels={3}
            className="w-full  border-[1px] p-2 rounded bg-white"
          />
        </div>
        <Button onClick={submitHandlder}>Create Folder</Button>
      </div>
    </main>
  );
};

export default FolderAccess;
